$(() => {
    let timeline = $(".timeline");
    let children = timeline.children();
    let move = 200;
    let indexDot = 0;

    if (children && children.length > 0) {
        let width = 0;

        timeline.children().each((index, el) => {
            $(el).css("left", width);
            $(el).attr('id', 'timeline' + index);
            width += 200;
        });
    }

    let currentTranslateValue = move;

    $(".e-timeline-carousel__navigation-button--prev").on("click", function () {
        if (indexDot === 0) {
            return;
        }

        currentTranslateValue += move;
        timeline.css(
            "transform",
            "translateX(" + currentTranslateValue + "px)"
        );

        indexDot -= 1;

        $('.timeline-item').removeClass('active');
        $('#' + 'timeline' + indexDot).addClass('active');

        $('.e-timeline-carousel__date h3, .e-timeline-carousel__person-number h3, .e-timeline-carousel__surface h3, .e-timeline-carousel__worker h3').each((_, element) => {
            if ($(element).hasClass('active')) {
                $(element).addClass('inactive');
                $(element).removeClass('active');
                setTimeout(() => {
                    $(element).removeClass('inactive');
                }, 500);
            }
        });
        $('.slide-' + indexDot).each((index, el) => {
            $(el).addClass('active');
        });
    });

    $(".e-timeline-carousel__navigation-button--next").on("click", function () {
        if (children.length - 1 <= indexDot) {
            return;
        }

        currentTranslateValue -= move;
        timeline.css(
            "transform",
            "translateX(" + currentTranslateValue + "px)"
        );

        indexDot += 1;

        $('.timeline-item').removeClass('active');
        $('#' + 'timeline' + indexDot).addClass('active');

        $('.e-timeline-carousel__date h3, .e-timeline-carousel__person-number h3, .e-timeline-carousel__surface h3, .e-timeline-carousel__worker h3')
            .each((_, element) => {
                if ($(element).hasClass('active')) {
                    $(element).addClass('inactive');
                    $(element).removeClass('active');
                    setTimeout(() => {
                        $(element).removeClass('inactive');
                    }, 500);
                }
            });

        $('.slide-' + indexDot).each((index, el) => {
            $(el).addClass('active');
        });
    });

    // init
    const dates = [];
    $('.e-timeline-carousel__date h3').each((_, element) => {
        dates.push(element.innerText);
    });

    $('.e-timeline-carousel__date').each((_, element) => {
        $(element).empty();
        dates.forEach((date, index) => {
            if (index === 0) {
                $(element).append('<h3 class="active slide-' + index + '">' + date + '</h3 > ');
            } else {
                $(element).append('<h3 class="slide-' + index + '">' + date + '</h3 > ');
            }

        })
    });

    // init
    const personNumbers = [];
    $('.e-timeline-carousel__person-number h3').each((_, element) => {
        personNumbers.push(element.innerText);
    });

    $('.e-timeline-carousel__person-number').each((_, element) => {
        $(element).empty();
        personNumbers.forEach((date, index) => {
            if (index === 0) {
                $(element).append('<h3 class="e-timeline-carousel__item-person active slide-' + index + '">' + date + '</h3 > ');
            } else {
                $(element).append('<h3 class="e-timeline-carousel__item-person slide-' + index + '">' + date + '</h3 > ');
            }

        })
    });

    // init
    const surfaces = [];
    $('.e-timeline-carousel__surface h3').each((_, element) => {
        surfaces.push(element.innerHTML);
    });

    $('.e-timeline-carousel__surface').each((_, element) => {
        $(element).empty();
        surfaces.forEach((date, index) => {
            if (index === 0) {
                $(element).append('<h3 class="e-timeline-carousel__item-person active slide-' + index + '">' + date + '</h3 > ');
            } else {
                $(element).append('<h3 class="e-timeline-carousel__item-person slide-' + index + '">' + date + '</h3 > ');
            }

        })
    });

    // init
    const workers = [];
    $('.e-timeline-carousel__worker h3').each((_, element) => {
        workers.push(element.innerText);
    });

    $('.e-timeline-carousel__worker').each((_, element) => {
        $(element).empty();
        workers.forEach((date, index) => {
            if (index === 0) {
                $(element).append('<h3 class="e-timeline-carousel__item-person active slide-' + index + '">' + date + '</h3 > ');
            } else {
                $(element).append('<h3 class="e-timeline-carousel__item-person slide-' + index + '">' + date + '</h3 > ');
            }

        })
    });

});
