$(() => {
    var currentFormToValid = "";

    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
      });

    $.validator.addMethod("valueNotEquals", function(value, element, arg) {
        return arg !== value;
    }, "Value must not equal arg.");

    function setCurrentFormToValid(currentForm) {
        currentFormToValid = '#' + currentForm;
    }

    function checkFormValidity(currentForm) {
        setCurrentFormToValid(currentForm);

        var isValid = $(currentFormToValid).valid();

        return isValid;
    }

    $('form').on('submit', function(e) {
        e.preventDefault();
        let currentForm = $(this).closest('form').attr("id");
        let isFormValid = checkFormValidity(currentForm);

        if (isFormValid === true) {
            const getUrl = window.location;
            const targetUrl = `${getUrl.origin}/umbraco/Surface/Contact/SaveContactEmail`;
            const formData = new FormData($(this).closest('form')[0]);

            $.ajax({
                method: "POST",
                url: targetUrl,
                data: formData,

                cache: false,
                contentType: false,
                processData: false
            }).done(function(r) {
                $('.m-contact__confirmation').addClass('show');
            });
        }
    });

    // Placeholder fix

    if ($('.e-form__input').length) {
        $('.e-form__input').on('blur', 'input:not(required)', function() {
            console.log($(this));
            const parent = $(this).closest('.e-form__input');
            if ($(this)[0].value) {
                parent.addClass('filled');
            } else {
                parent.removeClass('filled');
            }
        })
    }

    // Resizeable textarea
    var observe;
    if ($('.e-form__input textarea').length) {
        if (window.attachEvent) {
            observe = function (element, event, handler) {
                element.attachEvent('on'+event, handler);
            };
        }
        else {
            observe = function (element, event, handler) {
                element.addEventListener(event, handler, false);
            };
        }

        function initTextarea () {
            var text = document.querySelector('.e-form__input textarea');
            function resize () {
                text.style.height = 'auto';
                text.style.height = text.scrollHeight - 4 +'px';
            }

            /* 0-timeout to get the already changed text */
            function delayedResize () {
                window.setTimeout(resize, 0);
            }

            observe(text, 'change',  resize);
            observe(text, 'cut',     delayedResize);
            observe(text, 'paste',   delayedResize);
            observe(text, 'drop',    delayedResize);
            observe(text, 'keydown', delayedResize);

            text.focus();
            text.select();
            resize();
        }

        initTextarea();
    }

    // File inputs
    const fileInput = $('input[type=file]:not(.dz-hidden-input)');
    const clearFileInput = $('.e-form__clear-file');
    clearFileInput.on('click', function(e) {
        e.preventDefault();
        const parent = $(this).closest('.e-form__input--fileupload');
        const fileInput = parent.find('input[type=file]');
        fileInput.value = '';
        fileInput.removeClass('fileuploaded');
        fileInput.attr('data-filename', '');
        parent.removeClass('hide-dl').removeClass('filled');
    });

    fileInput.on('change', function() {
        if($(this).prop('files').length) {
            console.log($(this).prop('files'));
            $(this).addClass('fileuploaded');
            $(this).closest('.e-form__input--fileupload').addClass('hide-dl');
            $(this).attr('data-filename', $(this).prop('files')[0].name);
        } else {
            $(this).removeClass('fileuploaded');
            $(this).closest('.e-form__input--fileupload').removeClass('hide-dl').removeClass('filled');
            $(this).attr('data-filename', '');
        }
    });
});
