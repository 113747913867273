$(() => {
    const directions__container = document.querySelector('.m-directions');
    if (directions__container === null) return false;

    const directions__targetAddress = directions__container.dataset.targetAddress;
    const directions__targetLat = directions__container.dataset.targetLat;
    const directions__targetLng = directions__container.dataset.targetLng;

    if (directions__targetAddress === undefined || directions__targetLat === undefined || directions__targetLng === undefined) {
        return false;
    }

    const directions__tabs = directions__container.querySelectorAll('.m-directions__tab');
    const directions__fromInput = directions__container.querySelector('.m-directions__from input');
    const directions__toInput = directions__container.querySelector('.m-directions__to input');
    const directions__switchBtn = directions__container.querySelector('.m-directions__arrows');
    const directions__openRouteBtn = directions__container.querySelector('.m-directions__btn');

    let directions__isDirectionReversed = false;

    directions__initForm();

    directions__tabs.forEach(tab => tab.addEventListener('click', directions__tabClick));
    directions__switchBtn.addEventListener('click', directions__switchInputs);
    directions__openRouteBtn.addEventListener('click', directions__openRoute);

    function directions__tabClick(event) {
        directions__tabs.forEach(tab => tab.classList.remove('active'));
        event.target.classList.add('active');
    }

    function directions__switchInputs() {
        directions__isDirectionReversed = !directions__isDirectionReversed;

        if (directions__isDirectionReversed) {
            directions__toInput.value = directions__fromInput.value;
            directions__fromInput.value = directions__targetAddress;

            directions__toInput.disabled = false;
            directions__fromInput.disabled = true;
        } else {
            directions__fromInput.value = directions__toInput.value;
            directions__toInput.value = directions__targetAddress;

            directions__fromInput.disabled = false;
            directions__toInput.disabled = true;
        }
    }

    function directions__initForm() {
        directions__toInput.value = directions__targetAddress;
        directions__toInput.disabled = true;
    }

    function directions__openRoute() {
        const travelMode = Array.from(directions__tabs).find(tab => tab.classList.contains('active')).dataset.travelMode;

        let url = `https://www.google.com/maps/dir/?api=1`;

        if (directions__isDirectionReversed) {
            url += `&origin=${directions__targetLat},${directions__targetLng}`;
            url += `&destination=${directions__toInput.value}`;
        } else {
            url += `&origin=$${directions__fromInput.value}`;
            url += `&destination=${directions__targetLat},${directions__targetLng}`;
        }

        url += `&travelmode=${travelMode}`;

        window.open(url, '_blank');
    }
});