$(() => {

    if ($('.m-social-details').length) {
        $('[data-social-overlay]').on('click', function(e) {
            e.preventDefault();
            const targetModal = `#${$(this).data('social-overlay')}`;
            $(targetModal).addClass('open');
            $('body, html').addClass('blocked');
            initSocialDetailsSwipers($(this).data('social-item'));
        });
        $('.m-social-details').on('click', '.m-social-details__close', function() {
            $(this).closest('.m-social-details').removeClass('open');
            $('body, html').removeClass('blocked');
        })
    }

    function initSocialDetailsSwipers(slideNr) {
        var galleryMain = new Swiper('.m-social-details__main-swiper', {
            spaceBetween: 152,
            simulateTouch: false,
            initialSlide: slideNr - 1,
            autoHeight: true,
            navigation: {
                prevEl: ".m-social-details__navigation-button--prev",
                nextEl: ".m-social-details__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    spaceBetween: 30,
                },
                768: {
                    spaceBetween: 80,
                },
                1439: {
                    spaceBetween: 120,
                }
            },
            on: {
                init: function() {
                    const slides = $('[data-slide-nr]');
                    slides.each(function() {
                        const currentSlideNr = $(this).data('slide-nr');
                        var galleryTop = new Swiper(`#gallery-${currentSlideNr}`, {
                            spaceBetween: 10,
                            simulateTouch: false,
                            pagination: {
                                el: '.m-social-details__gallery-pagin',
                                type: 'bullets',
                                clickable: true
                            },
                        });
                    });
                },
            }
        });
    }
});
