$(() => {
    const googleAnalyticsPropertyID = 'UA-141887062-1';
    const googleTagManagerPropertyID = 'G-SJPPFCZFZ2';
    const cookies__consentName = '_cc';
    const cookies__analyticsConsentName = '_ac';

    const cookies__overlay = document.getElementById('cookies-overlay')
    if (cookies__overlay === null) return false;

    const cookies__overviewPage = cookies__overlay.querySelector('.m-cookies__overview');
    const cookies__showSettingsBtn = cookies__overlay.querySelector('.m-cookies__show-settings');
    const cookies__acceptOverviewBtn = cookies__overlay.querySelector('.m-cookies__accept-overview');

    const cookies__settingsPage = cookies__overlay.querySelector('.m-cookies__settings');
    const cookies__settingsRequiredCheckboxes = cookies__settingsPage.querySelectorAll('input[type=checkbox]:not(.toggle):required');
    const cookies__settingsCheckbox3 = document.getElementById('m-cookies__accept-3');
    const cookies__settingsAnalyticsCheckbox = document.getElementById('m-cookies__accept-analytics');
    const cookies__acceptSettingsBtn = cookies__overlay.querySelector('.m-cookies__accept-settings');

    cookies__checkAnalyticsConsent();
    cookies__checkCookiesConsent();

    function cookies__checkAnalyticsConsent() {
        if (localStorage.getItem(cookies__analyticsConsentName) !== null) {
            cookies__addAnalyticsScript();
            cookies__addTagManagerScript();
        };
    }

    function cookies__checkCookiesConsent() {
        if (localStorage.getItem(cookies__consentName) === null) {
            cookies__showOverviewPage();
        };
    }

    function cookies__showOverviewPage() {
        cookies__overlay.classList.add('open');

        cookies__acceptOverviewBtn.addEventListener('click', () => {
            cookies__overlay.classList.remove('open');

            localStorage.setItem(cookies__consentName, '1');
            localStorage.setItem(cookies__analyticsConsentName, '1');
            cookies__addAnalyticsScript();
            cookies__addTagManagerScript();
        });

        cookies__showSettingsBtn.addEventListener('click', cookies__showSettingsPage, { once: true });
    }

    function cookies__showSettingsPage() {
        cookies__overviewPage.classList.remove('show');
        cookies__settingsPage.classList.add('show');

        cookies__settingsRequiredCheckboxes.forEach(check => check.addEventListener('change', cookies__toogleSettingsBtn));

        cookies__settingsCheckbox3.addEventListener('change', () => {
            cookies__settingsAnalyticsCheckbox.checked = cookies__settingsCheckbox3.checked;
        });
        cookies__settingsAnalyticsCheckbox.addEventListener('change', () => {
            cookies__settingsCheckbox3.checked = cookies__settingsAnalyticsCheckbox.checked;
        });

        cookies__acceptSettingsBtn.addEventListener('click', () => {
            if (!cookies__toogleSettingsBtn()) return false;

            cookies__overlay.classList.remove('open');

            localStorage.setItem(cookies__consentName, '1');

            if (cookies__settingsAnalyticsCheckbox.checked) {
                localStorage.setItem(cookies__analyticsConsentName, '1');
                cookies__addAnalyticsScript();
                cookies__addTagManagerScript();
            }
        });
    }

    function cookies__toogleSettingsBtn() {
        const allChecked = Array.from(cookies__settingsRequiredCheckboxes).every(check => check.checked);

        if (allChecked) {
            cookies__acceptSettingsBtn.classList.remove('disabled');
        } else {
            cookies__acceptSettingsBtn.classList.add('disabled');
        }

        return allChecked;
    }

    function cookies__addAnalyticsScript() {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '${googleAnalyticsPropertyID}', 'auto');
        ga('send', 'pageview');
    `;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    function cookies__addTagManagerScript() {
        const scriptAsync = document.createElement("script");
        const script = document.createElement("script");
        scriptAsync.type = "text/javascript";
        script.type = "text/javascript";
        scriptAsync.async = true;
        scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerPropertyID}`
        script.innerHTML = `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${googleTagManagerPropertyID}");`;
        document.getElementsByTagName('head')[0].appendChild(scriptAsync);
        document.getElementsByTagName('head')[0].appendChild(script);
    }
});
