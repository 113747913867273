$(() => {
    // SVGInject
    SVGInject(document.querySelectorAll("img.injectable"));

    /* 100vh trick */
    function getVhValue() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', () => {
        getVhValue();
    });

    getVhValue();

    //  pressed button state
    $(".e-button").on("click", function(e) {
        // e.preventDefault();
        $(this).addClass("pressed");
        setTimeout(element => {
            $(this).removeClass("pressed");
        }, 1000);
    });

    /* change color of navbar and header on scroll */
    const header = $('#header');
    const menu = $('#mobile-menu');
    const sticky = $('.sticky-header').length;


    function stickHeader() {
        if ($(window).scrollTop() > 0) {
            header.addClass('active');
            menu.addClass('scrolled');
        } else {
            header.removeClass('active');
            menu.removeClass('scrolled');
        }
    };

    if (sticky) {
        stickHeader();

        $(window).scroll(function() {
            stickHeader();
        });
    }

    /*
        change position of underline in nav
    */

    if ($('.e-nav__current').length) {
        let nav_current = $('.e-nav__current');
        let nav_menu = $('.e-nav__menu');

        function moveLine(el) {
            if (el.length !== 0) {
                nav_current.css('width', el.outerWidth());
                nav_current.css('left', el.position().left);
                nav_current.css('opacity', 1)
            } else {
                nav_current.css('opacity', 0);
            }
        };

        moveLine($('.e-nav__menu').find('.current'));

        nav_menu.children('li').hover(function() {
             moveLine($(this));
        });

        $(window).resize(function() {
            moveLine($('.e-nav__menu').find('.current'));
        });

        nav_menu.mouseleave(function() {
            moveLine($('.e-nav__menu').find('.current'));
        });
    }

    /*
        submenu in mobile menu things
    */

    let menu_toggler = $('.e-nav__toggler');
    let mobile_menu = $('.e-mobile-menu');
    let mobile_items = document.getElementById('mobile-menu-items');

    menu_toggler.click(function() {
        $(this).toggleClass('active');
        mobile_menu.toggleClass('active');

        if (mobile_menu.hasClass('active')) {
            bodyScrollLock.disableBodyScroll(mobile_items);
        } else {
            bodyScrollLock.enableBodyScroll(mobile_items);
        }
    });

    /*
        slider filter
    */

    var slider_input = document.getElementById("slider-input");
    var slider_output = document.getElementById("slider-output");
    var slider_range = document.getElementById("slider-range");

    if (slider_input && slider_output && slider_range) {
        var input_value = slider_input.value;

        slider_output.innerHTML = input_value;
        slider_range.style.width = input_value + "%";

        slider_input.oninput = function() {
          slider_output.innerHTML = this.value;
          slider_range.style.width = this.value + "%";
        }
    };

    if ($(window).width() < 992) {
        $('.e-dropdown').click(function(e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });

        $(document).click(function(e) {
            if ($('.e-dropdown').hasClass('active') && $(e.target).closest('.e-dropdown').length == 0) {
                $('.e-dropdown').removeClass('active')
            }
        });
    }


    // tabs

    let tabs_current = $('.e-tabs__current');
    let tabs_item = $('.e-tabs__item');

    function moveTabsLine(el) {
        let left = el.position().left;

        if (el.parent().hasClass('swiper-slide')) {
            left = el.parent().position().left;
        }

        tabs_current.css('width', el.outerWidth());
        tabs_current.css('left', left);
    };

    if (tabs_item.length) {

        setTimeout(function() {
            moveTabsLine($('.e-tabs__item.active'));
        }, 200);

        tabs_item.hover(function() {
            moveTabsLine($(this));
        });

        $(window).resize(function() {
            moveTabsLine($('.e-tabs__item.active'));
        });

        $('.e-tabs').mouseleave(function() {
            moveTabsLine($('.e-tabs__item.active'));
        });
    }

    function changeTabContent(el) {
        var id = el.data('id');

        $('.e-tab-content').hide();

        $('.e-tab-content[data-id="' + id + '"]').fadeIn();

        if ($(`.e-tab-content[data-id="${id}"]`).find('.e-image-gallery').length) {
            setTimeout(function() {
                initSimpleGallery();
            }, 201);
        }
    }

    changeTabContent($('.e-tabs__item.active'));

    tabs_item.click(function() {
        tabs_item.removeClass('active');
        $(this).addClass('active');

        changeTabContent($(this));
    });

    // collapse galleries

    if ($('input[type=checkbox].toggle').length) {
        $('input[type=checkbox].toggle').on('change', function() {
            if($(this).closest('.m-collapse-item').find('.e-image-gallery').length) {
                setTimeout(function() {
                    initSimpleGallery();
                }, 201);
            }
        });
    }


    // get route underline
    let get_route_current = $('.e-get-route__current');
    let get_route_menu = $('.e-get-route__nav');

    if (get_route_menu.length) {

        function moveLineRoute(el) {
            get_route_current.css('width', el.outerWidth());
            get_route_current.css('left', el.position().left);
        };

        moveLineRoute($('.e-get-route__nav').find('.active'));

        $(window).resize(function() {
            moveLineRoute($('.e-get-route__nav').find('.active'));
        });

        get_route_menu.children('li').hover(function() {
            moveLineRoute($(this));
        });

        get_route_menu.children('li').click(function() {
            get_route_menu.children('li').removeClass('active');
            $(this).addClass('active');
        });

        get_route_menu.mouseleave(function() {
            moveLineRoute($('.e-get-route__nav').find('.active'));
        });
    }

    // contact content
    let contact_choice = $('.e-fav--contact');
    let contact_content = $('.contact-content');

    function getContactContent(el) {
        contact_choice.removeClass('active');
        el.addClass('active');

        contact_content.hide();
        $('.contact-content[data-id="' + el.data('id') + '"]').fadeIn();
    }

    contact_choice.click(function(e) {
        e.preventDefault();
        getContactContent($(this));

        $('html, body').stop().animate({
            scrollTop: $('.m-contact-choice').offset().top + $('.m-contact-choice').innerHeight() - 50
        }, 500);
    });

    getContactContent($(contact_choice[0]));

    // get route inputs
    let route_from = $('.e-get-route__input--from').find('input');
    let route_to = $('.e-get-route__input--to').find('input');
    let route_change = $('.e-get-route__chevron');

    route_change.click(function() {
        var from = route_from.val();
        var to = route_to.val();

        route_from.val(to);
        route_to.val(from);
    });

    //vimeo video
    if ($('.m-video__box').length) {

        $('.m-video__play-btn').on('click', function() {
            const iframe = $(this).closest('.m-video__box').find('.m-video__iframe')[0];
            const serverVideo = $(this).closest('.m-video__box').find('video')[0];
            let isVimeo;

            if (iframe) {
                isVimeo = iframe['src'].includes('vimeo');
            }

            if (iframe && isVimeo) {
                const player = new Vimeo.Player(iframe);
                player.play().then(function() {
                    // The video is playing
                  }).catch(function(error) {
                    switch (error.name) {
                      case 'PasswordError':
                          // The video is password-protected
                          break;

                      case 'PrivacyError':
                          // The video is private
                          break;

                      default:
                          // Some other error occurred
                          break;
                    }
                });
            }

            if (iframe && !isVimeo) {
                iframe['src'] += '&autoplay=1';
            }

            if (serverVideo) {
                serverVideo.play();
            }

            if ($(this).closest('.m-video__overlay').length) {
                $(this).closest('.m-video__overlay').fadeOut(200);
            }
        })
    }

    if ($('.e-news').length) {
        $('.e-news').each(function() {
            const height = $(this).find('.e-news__date').outerHeight() + $(this).find('h3').outerHeight();
            const media = window.matchMedia('(max-width: 991px)')
            if (media.matches) {
                $(this).find('.e-news__content').css('minHeight', height + 50);
            } else {
                $(this).find('.e-news__content').css('minHeight', height + 80);
            }
        })
    }

    if ($('.e-mobile-multi__trigger').length) {
        $('.e-mobile-multi__trigger').on('click', function(e) {
            e.preventDefault();
            const target = $(`[data-header-menu=${$(this).data('header-open')}]`);
            target.addClass('active');
            $('.e-mobile-menu__items').addClass('submenu-opened');
        });

        $('.e-mobile-multi__back').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.e-mobile-multi__menu').removeClass('active');

            if(!$('.e-mobile-multi__menu.active').length) {
                $('.e-mobile-menu__items').removeClass('submenu-opened');
            }
        });
    }

    if ($('.m-product-overlay').length) {
        $('[data-prod-overlay]').on('click', function(e) {
            e.preventDefault();
            const targetModal = `#${$(this).data('prod-overlay')}`;
            $(targetModal).addClass('open');
            $('body, html').addClass('blocked');
            initProductOverlaySwipers($(this).data('prod-item'));
        });
        $('.m-product-overlay').on('click', '.m-product-overlay__close', function() {
            $(this).closest('.m-product-overlay').removeClass('open');
            $('body, html').removeClass('blocked');
        })
    }

    if ($('[data-modal]').length) {
        $('[data-open-overlay]').on('click', function(e) {
            e.preventDefault();
            const targetModal = `#${$(this).data('open-overlay')}`;
            $(targetModal).addClass('open');
            $('body, html').addClass('blocked');
        });
        $('[data-modal]').on('click', '[data-modal-close]', function() {
            const confirmationMessage = $(this).closest('[data-modal]').find('.m-contact__confirmation');
            $(this).closest('[data-modal]').removeClass('open');
            if (confirmationMessage.length) {
                confirmationMessage.removeClass('show');
            }
            $('body, html').removeClass('blocked');
        })
    }

    initSimpleGallery();
    initImageTextGallery();

    if ($('.e-news').length) {
        $('.e-news').on('mouseleave', function() {
            $(this).find('.e-news__content').scrollTop(0);
        });
    }

    if ($('#login-pin').length) {
        const sendBtn = $('.m-login-panel').find('.m-login-panel__btn');

        $('#login-pin').pincodeInput({
            inputs: 6,
            hidedigits: false,
            change: function(input, value, inputNumber) {
                if (value !== '') {
                    $(input).closest('.m-login-panel__pinbox').addClass(`f-${inputNumber}`);
                } else {
                    $(input).closest('.m-login-panel__pinbox').removeClass(`f-${inputNumber}`);
                }
                if (value === '' && !sendBtn.hasClass('disabled')) {
                    sendBtn.addClass('disabled');
                }
            },
            complete: function(value, e, errorElement) {
                sendBtn.removeClass('disabled');
            }
        });
    }

    if($('[data-toggle-quicklinks]').length) {
        const trigger = $('[data-toggle-quicklinks]');
        const linkList = $('[data-quicklinks]');

        trigger.on('click', function(e) {
            e.preventDefault();
            trigger.toggleClass('open');
            linkList.toggleClass('active');
        })
    }

    if($('.e-nav__menu').length) {
        const parent = $('.e-nav__container');
        const logoWidth = parent.find('.e-nav__logo').outerWidth();
        const menuWidth = parent.find('.e-nav__menu').outerWidth();
        const btnWidth = parent.find('.e-nav__btn').outerWidth();
        $(window).on('resize', function() {
            const parentWidth = parent.outerWidth();
            const media = window.matchMedia('(max-width: 991px)')
            if (!media.matches) {
                if((parentWidth - logoWidth - btnWidth) <= (menuWidth + 50)) {
                    $('body').addClass('set-mobile-menu');
                } else {
                    $('body').removeClass('set-mobile-menu');
                }
            } else {
                $('body').removeClass('set-mobile-menu');
            }
        });
        $(window).trigger('resize');
    }

    if ($('.m-collapse-item__card-contact').length) {
        addSoftBreaksToContactEmails();
        equalizeContactBoxText();
    }

    if ($('[data-timeline-numbers]').length) {
        $(window).on('resize', function() {
            $('[data-timeline-numbers]').each(function() {
                const nextTextHeight = $(this).siblings('p').outerHeight();
                $(this).css('bottom', nextTextHeight + 6);
            })
        }).trigger('resize');
    }

    if ($('.m-job-overview__box').length) {
        $(window).on('resize', equalizeJobOverviewBoxes).trigger('resize');
    }
});

function equalizeJobOverviewBoxes() {
    let maxHeight = 0;
    const boxes = $('.m-job-overview .m-job-overview__box');
    boxes.css('minHeight', 0);

    boxes.each( function() {
        if ($(this).outerHeight() > maxHeight) {
            maxHeight = $(this).outerHeight();
        }
    });
    boxes.css('minHeight', maxHeight);
}

function equalizeContactBoxText() {
    //let maxHeight = 0;
    let maxPersonalHeight = 0;
    //$('.m-collapse-item__card-contact').each(function() {
    //    if ($(this).outerHeight() > maxHeight) {
    //        maxHeight = $(this).outerHeight();
    //    }
    //});
    $('.m-collapse-item__card-personal').each(function() {
        if ($(this).outerHeight() > maxPersonalHeight) {
            maxPersonalHeight = $(this).outerHeight();
        }
    });
    $('.m-collapse-item__card-contact').css('minHeight', '17px');
    $('.m-collapse-item__card-personal').css('minHeight', maxPersonalHeight);
}

function addSoftBreaksToContactEmails() {
    $('.m-collapse-item__card-contact-item.mail').each(function() {
        let str = ($(this).find('a').text());
        let res = str.split('@');
        $(this).find('a').html(`${res[0]}@&#8203;${res[1]}`);
    })
}

function initProductOverlaySwipers(slideNr) {
    var galleryMain = new Swiper('.m-product-overlay__main-swiper', {
        spaceBetween: 152,
        simulateTouch: false,
        initialSlide: slideNr - 1,
        navigation: {
            prevEl: ".m-product-overlay__navigation-button--prev",
            nextEl: ".m-product-overlay__navigation-button--next",
            disabledClass: "disabled"
        },
        breakpoints: {
            320: {
                spaceBetween: 30,
            },
            768: {
                spaceBetween: 80,
            },
            1439: {
                spaceBetween: 120,
            }
        },
        on: {
            init: function() {
                const slides = $('[data-slide-nr]');
                slides.each(function() {
                    const currentSlideNr = $(this).data('slide-nr');
                    var galleryThumbs = new Swiper(`#thumbs-${currentSlideNr}`, {
                        spaceBetween: 10,
                        slidesPerView: 4,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                    });
                    var galleryTop = new Swiper(`#gallery-${currentSlideNr}`, {
                        spaceBetween: 10,
                        thumbs: {
                            swiper: galleryThumbs
                        }
                    });
                });
            },
        }
    });
}


function initGalleryForSwipers() {
    const gallerySwiper = $('.e-gallery-swiper');

    if (gallerySwiper) {
        let swiper = new Swiper(gallerySwiper, {
            speed: 600,
            direction: "horizontal",
            resistanceRatio: 0,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false
            },
            navigation: {
                prevEl: ".e-gallery-swiper__navigation-button--prev",
                nextEl: ".e-gallery-swiper__navigation-button--next",
                disabledClass: 'disabled'
            },
            pagination: {
                el: ".e-gallery-swiper__fraction",
                type: "custom",
                renderCustom: function(swiper, current, total) {
                    return ("0" + current).slice(-2);
                }
            },
            breakpoints: {
                992: {
                    allowTouchMove: false
                }
            }
        });
    }
}

function initSimpleGallery() {

    const simpleGallery = $('.e-image-gallery__swiper');
    const simpleGalleryArray = [];

    simpleGallery.each(function(index) {
        const simpleGalleryUniqueId = '#' + $(this).attr('id');
        const parent = $(simpleGalleryUniqueId).closest('.e-image-gallery');
        const prevBtn = parent.find('.e-image-gallery__navigation-button--prev');
        const nextBtn = parent.find('.e-image-gallery__navigation-button--next');
        const pagin = parent.find('.e-image-gallery__pagination');
        const simpleGalleryUnique = $(simpleGalleryUniqueId);

        const simpleGallerySwiper = new Swiper(simpleGalleryUnique, {
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: true,
            paginationClickable: true,
            slidesPerView: 1,
            autoHeight: true,
            pagination: {
                el: pagin,
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: prevBtn,
                nextEl: nextBtn,
            },
            on: {
                slideChange: function() {
                    pagin.find('.swiper-pagination-bullet').removeClass('swiper-pagination-bullet-active');
                    pagin.find('.swiper-pagination-bullet').eq($(this)[0].snapIndex).addClass('swiper-pagination-bullet-active');
                    $(this)[0].pagination.update();
                }
            }
        });

        simpleGalleryArray.push(simpleGallerySwiper);
    });
}

function initImageTextGallery() {

    const imageTextGallery = $('.e-image-gallery-text__swiper');
    const imageTextGalleryArray = [];
    const imageTextCardSwiperArray = [];

    imageTextGallery.each(function(index) {
        const imageTextGalleryUniqueId = '#' + $(this).attr('id');
        const parent = $(imageTextGalleryUniqueId).closest('.e-image-gallery');
        const cardSwiper = parent.find('.e-image-gallery__card-swiper');
        const cardSwiperId = '#' + cardSwiper.attr('id');
        const prevBtn = parent.find('.e-image-gallery__navigation-button--prev');
        const nextBtn = parent.find('.e-image-gallery__navigation-button--next');
        const pagin = parent.find('.e-image-gallery__pagination');
        const imageTextGalleryUnique = $(imageTextGalleryUniqueId);

        const cardSwiperInstance = new Swiper(cardSwiperId, {
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: false,
            paginationClickable: false,
            slidesPerView: 1,
            effect: 'fade',
        });

        const imageTextGallerySwiper = new Swiper(imageTextGalleryUnique, {
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: false,
            paginationClickable: true,
            slidesPerView: 1,
            pagination: {
                el: pagin,
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: prevBtn,
                nextEl: nextBtn,
            },
            on: {
                slideChange: function() {
                    pagin.find('.swiper-pagination-bullet').removeClass('swiper-pagination-bullet-active');
                    pagin.find('.swiper-pagination-bullet').eq($(this)[0].snapIndex).addClass('swiper-pagination-bullet-active');
                    cardSwiperInstance.slideTo($(this)[0].snapIndex);
                    $(this)[0].pagination.update();
                }
            }
        });

        imageTextGalleryArray.push(imageTextGallerySwiper);
        imageTextCardSwiperArray.push(cardSwiperInstance);
    });
}
