const closeAllSelect = (elmnt) => {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName('e-select__items');
    y = document.getElementsByClassName('e-select__selected');
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove('active');
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add('hide');
        }
    }
}

/* Look for any elements with the class 'e-select': */
const containers = [...document.getElementsByClassName('e-select')];
for (const container of containers) {
    const select = container.getElementsByTagName('select')[0];

    /* For each element, create a new DIV that will act as the selected item: */
    const selected = document.createElement('DIV');
    selected.classList.add('e-select__selected');
    selected.innerHTML = select.options[select.selectedIndex].innerHTML;
    container.appendChild(selected);

    /* For each element, create a new DIV that will contain the option list: */
    const items = document.createElement('DIV');
    items.classList.add('e-select__items', 'hide');

    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    for (const option of select.options) {
        const item = document.createElement('DIV');
        item.innerHTML = option.innerHTML;

        /* When an item is clicked, update the original select box,
        and the selected item: */
        item.addEventListener('click', function (e) {
            const selectedIndex = [...items.children].indexOf(this);

            select.selectedIndex = selectedIndex;
            selected.innerHTML = this.innerHTML;

            if (selectedIndex > 0) {
                selected.classList.add('selected');
            } else {
                selected.classList.remove('selected');
            }

            for (let item of items.children) {
                item.classList.remove('active');
            }
            this.classList.add('active');

            selected.click();
            select.dispatchEvent(new Event('input'));
        });
        items.appendChild(item);
    }
    items.firstChild.classList.add('active');
    container.appendChild(items);

    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    selected.addEventListener('click', function (e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle('hide');
        this.classList.toggle('active');
        //Popup dropdown check
        if ($(this).closest('.m-booking').length > 0) {
            if ($(this)[0].previousElementSibling.options[0].label == $(this)[0].innerHTML && !$(this).hasClass('active')) {
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        }
    });
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener('click', closeAllSelect);