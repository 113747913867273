$(() => {

    if ($('.m-process__gallery').length) {
        const process_swiper = $('.m-process__gallery');

        if (process_swiper.length) {
            let processSwiper = new Swiper(process_swiper, {
                speed: 1000,
                direction: "horizontal",
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                paginationClickable: true,
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                effect: 'fade',
                navigation: {
                    prevEl: ".m-process__navigation-button--prev",
                    nextEl: ".m-process__navigation-button--next",
                    disabledClass: "disabled"
                },
                breakpoints: {
                },
                pagination: {
                    el: '.m-process__pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    slideChange: function() {
                        const wheel = $('.m-process .e-animation-wheel__window ul');
                        wheel.attr('class', '');
                        wheel.addClass(`rotate-${this.realIndex + 1}`);
                    }
                }
            });
        }
    }
});
