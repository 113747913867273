Dropzone.autoDiscover = false;

$(() => {
    /**
     * Swiper
     */
    const applicationSwiperObj = document.querySelector('.application-swiper');
    if (applicationSwiperObj === null) { return; }

    const applicationSwiper = new Swiper(applicationSwiperObj, {
        init: false,
        speed: 600,
        direction: 'horizontal',
        watchSlidesProgress: false,
        watchSlidesVisibility: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 40,
        loop: false,
        noSwiping: true,
        noSwipingClass: 'application-swiper',
        pagination: {
            el: '.application-swiper__pagination',
            type: 'bullets',
            clickable: true, // TODO: Change to false for production
        },
        navigation: {
            nextEl: '.application-swiper__next',
            disabledClass: 'disabled',
            lockClass: 'disabled',
        },
        breakpoints: {
        },
    });

    const applicationSwiperStart__btn = document.querySelectorAll('[data-application-overlay-start]');
    const applicationSwiperBg = applicationSwiperObj.querySelector('.application-swiper__bg');

    applicationSwiperStart__btn.forEach(btn => {
        btn.addEventListener('click', onSwiperStart);
    });

    applicationSwiper.on('slideChange', () => {
        switch (applicationSwiper.activeIndex) {
            case 0:
            case 2:
            case 4:
                toogleBg(applicationSwiper.activeIndex);
                pi__lockLastTwo()
                applicationSwiper.allowSlideNext = true;
                break;

            case 1:
                toogleBg(1);
                sa__toogleBtn();
                pi__lockLastTwo()
                break;

            case 3:
                toogleBg(3);
                pi__lockLastTwo()
                pi__toogleBtn();
                break;

            case 5:
                toogleBg(applicationSwiper.activeIndex);
                uf__toogleBtn();
                break;

            case 6:
                toogleBg(applicationSwiper.activeIndex);
                hidePagination();
                break;

            default:
                break;
        }
    });

    function onSwiperStart() {
        if (applicationSwiper.initialized) { return; }

        setTimeout(() => {
            applicationSwiper.init();
            const lastPagination = applicationSwiperObj.querySelector('.application-swiper__pagination span:last-child');
            lastPagination.parentNode.removeChild(lastPagination);
        }, 0);
    }

    function toogleBg(slideNo) {
        applicationSwiperBg.className = `application-swiper__bg application-swiper__bg--slide-${slideNo}`;
    }

    function hidePagination() {
        applicationSwiperObj.querySelector('.application-swiper__pagination').classList.add('hide');
    }


    /**
     * Form: Select Application
     */
    const sa__openPosition__radio = document.getElementById('select-application-form__open-position');
    const sa__openPosition__dropdown = document.getElementById('select-application-form__open-position-dropdown');

    const sa__apprenticeship__radio = document.getElementById('select-application-form__apprenticeship');
    const sa__apprenticeship__dropdown = document.getElementById('select-application-form__apprenticeship-dropdown');

    const sa__nextBtn = document.getElementById('select-application-form__btn');

    if (sa__openPosition__radio !== null && sa__openPosition__dropdown !== null &&
        sa__apprenticeship__radio !== null && sa__apprenticeship__dropdown !== null &&
        sa__nextBtn !== null
    ) {
        document.querySelectorAll('.select-application-form input[type=radio]')
            .forEach(radio => radio.addEventListener('change', sa__radioChange));

        document.querySelectorAll('.select-application-form select')
            .forEach(select => select.addEventListener('input', sa__selectChange));
    }

    function sa__radioChange() {
        sa__toogleDropdowns();
        sa__toogleBtn();
    }

    function sa__selectChange() {
        sa__toogleBtn();
    }

    function sa__toogleDropdowns() {
        if (!sa__openPosition__radio.checked) {
            sa__openPosition__dropdown.classList.add('lock');
        } else {
            sa__openPosition__dropdown.classList.remove('lock');
        }

        if (!sa__apprenticeship__radio.checked) {
            sa__apprenticeship__dropdown.classList.add('lock');
        } else {
            sa__apprenticeship__dropdown.classList.remove('lock');
        }
    }

    function sa__toogleBtn() {
        if (sa__openPosition__radio.checked) {
            const select = sa__openPosition__dropdown.querySelector('select');

            if (select.selectedIndex > 0) {
                applicationSwiper.allowSlideNext = true;
                sa__nextBtn.classList.remove('disabled');
            } else {
                applicationSwiper.allowSlideNext = false;
                sa__nextBtn.classList.add('disabled');
            }
        }
        else if (sa__apprenticeship__radio.checked) {
            const select = sa__apprenticeship__dropdown.querySelector('select');

            if (select.selectedIndex > 0) {
                applicationSwiper.allowSlideNext = true;
                sa__nextBtn.classList.remove('disabled');
            } else {
                applicationSwiper.allowSlideNext = false;
                sa__nextBtn.classList.add('disabled');
            }
        }
        else {
            applicationSwiper.allowSlideNext = true;
            sa__nextBtn.classList.remove('disabled');
        }
    }


    /**
     * Form: Personal information
     */
    const pi__inputs = document.querySelectorAll('.personal-information-form .e-form__input input');
    const pi__nextBtn = document.getElementById('personal-information-form__btn');

    pi__inputs.forEach(input => input.addEventListener('change', pi__toogleBtn));

    function pi__toogleBtn() {

        if ([...pi__inputs].every(input => !input.required || input.validity.valid)) {
            applicationSwiper.allowSlideNext = true;
            pi__nextBtn.classList.remove('disabled');
        } else {
            applicationSwiper.allowSlideNext = false;
            pi__nextBtn.classList.add('disabled');
        }
    }

    function pi__lockLastTwo() {
        const pi__lastTwo = document.querySelectorAll('.application-swiper__pagination .swiper-pagination-bullet:nth-last-child(-n+2)');

        if ([...pi__inputs].every(input => !input.required || input.validity.valid)) {
            pi__lastTwo.forEach(element => {
                element.classList.remove('disabled');
            });
        } else {
            pi__lastTwo.forEach(element => {
                element.classList.add('disabled');
            });
        }
    }


    /**
     * Form: Upload files
     */
    const uf__dropzoneId = 'upload-files-form__files-dropzone';
    const uf__allFilesMaxSize = 25; // max size of all files

    const uf__dropzoneContainer = document.getElementById(uf__dropzoneId);
    if (uf__dropzoneContainer === null) { return; }

    const uf__dropzoneElement = uf__dropzoneContainer.querySelector('.e-dropzone');
    const uf__textarea = document.querySelector('.upload-files-form .e-form__input textarea');
    const uf__checkboxs = document.querySelectorAll('.upload-files-form .e-checkbox input');

    // Get template's HTML and remove it from document object
    const uf__templateNode = document.querySelector(`#${uf__dropzoneId} .e-dropzone-template`);
    const uf__previewTemplate = uf__templateNode.innerHTML;
    uf__templateNode.parentNode.removeChild(uf__templateNode);

    const getUrl = window.location;
    const targetUrlFiles = `${getUrl.origin}/umbraco/surface/contact/sendform`;

    const uf__dropzone = new Dropzone(`#${uf__dropzoneId} .e-dropzone`, {
        url: targetUrlFiles,
        method: 'post',
        paramName: 'files',
        createImageThumbnails: false,
        filesizeBase: 1000, // base for calculating size, 1000 == 1 MB
        maxFilesize: uf__allFilesMaxSize, // size limit per file
        maxFiles: 99, // maximum number of files
        acceptedFiles: 'application/pdf,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,',
        uploadMultiple: true,
        autoQueue: true,
        autoProcessQueue: false,
        addRemoveLinks: true,
        hiddenInputContainer: `#${uf__dropzoneId}`, // element the hidden input field will be appended to
        dictDefaultMessage: 'Upload your files',
        dictFileTooBig: 'Your selected files are too big to continue, please make sure to reduce the size and select them again',
        dictInvalidFileType: 'Your uploaded file has wrong file type, please upload one of: pdf, jpg, png, doc, docx',
        dictCancelUpload: '',
        dictRemoveFile: '',
        previewTemplate: uf__previewTemplate,
    });

    // max size of all files in kB
    const uf__maxSize = uf__allFilesMaxSize * uf__dropzone.options.filesizeBase * 1000;
    let uf__currentSize = 0;

    uf__dropzone.on('addedfile', file => {
        uf__currentSize += file.size;
        uf__checkMaxSize();
        uf__toogleBtn();

        const position = file.name.lastIndexOf('.');
        const extension = file.name.substring(position + 1);

        file.previewElement.querySelector('.e-dropzone__extension').innerText = extension;
    });

    uf__dropzone.on('removedfile', file => {
        uf__currentSize -= file.size;
        uf__checkMaxSize();
        uf__toogleBtn();

        if (uf__dropzoneElement !== null) {
            uf__dropzoneElement.classList.remove('error');

            uf__dropzoneContainer.querySelectorAll('.e-dropzone__error').forEach(errorObj => {
                errorObj.classList.remove('show');
            })
        }
    });

    uf__dropzone.on('success', (file, response) => {
        applicationSwiper.slideNext();
    });

    uf__dropzone.on('error', (file, errorMsg, xhrError) => {
        if (uf__dropzoneElement !== null) {
            uf__dropzoneElement.classList.add('error');
        }

        if (xhrError !== undefined) {
            const error = uf__dropzoneContainer.querySelector('.e-dropzone__error--api');

            if (error !== null) {
                error.classList.add('show');
            }
        } else {
            const error = uf__dropzoneContainer.querySelector('.e-dropzone__error--custom');

            if (error !== null) {
                error.querySelector('span').innerText = errorMsg;
                error.classList.add('show');
            }
        }

    });

    uf__textarea.addEventListener('change', uf__toogleBtn);
    uf__checkboxs.forEach(checkbox => checkbox.addEventListener('change', uf__toogleBtn));

    function uf__toogleBtn() {
        if (applicationForm.checkValidity() && uf__dropzone.files.length > 0) {
            applicationSwiper.allowSlideNext = true;
            applicationForm__sendBtn.classList.remove('disabled');
        } else {
            applicationSwiper.allowSlideNext = false;
            applicationForm__sendBtn.classList.add('disabled');
        }
    }

    function uf__checkMaxSize() {
        const error = uf__dropzoneContainer.querySelector('.e-dropzone__error--custom');

        if (uf__dropzoneElement !== null && error !== null) {
            if (uf__currentSize > uf__maxSize) {
                uf__dropzoneElement.classList.add('error');
                error.querySelector('span').innerText = uf__dropzone.options.dictFileTooBig;
                error.classList.add('show');
            } else {
                uf__dropzoneElement.classList.remove('error');
                error.classList.remove('show');
            }
        }
    }


    /**
     * Upload video Dropzone
     */
    const uv__dropzoneId = 'upload-files-form__video-dropzone';

    const uv__dropzoneContainer = document.querySelector(`#${uv__dropzoneId}`);
    const uv__dropzoneElement = uv__dropzoneContainer.querySelector('.e-dropzone');

    // Get template's HTML and remove it from document object
    const uv__templateNode = document.querySelector(`#${uv__dropzoneId} .e-dropzone-template`);
    const uv__previewTemplate = uv__templateNode.innerHTML;
    uv__templateNode.parentNode.removeChild(uv__templateNode);

    const targetUrlVideos = `${getUrl.origin}/umbraco/api/application/sendvideo`;

    const uv__dropzone = new Dropzone(`#${uv__dropzoneId} .e-dropzone`, {
        url: targetUrlVideos,
        method: 'post',
        paramName: 'HttpPostedFileBase',
        createImageThumbnails: false,
        filesizeBase: 1000, // base for calculating size, 1000 == 1 MB
        maxFilesize: 250, // size limit per file
        maxFiles: 1, // maximum number of files
        acceptedFiles: 'video/mp4',
        autoQueue: true,
        autoProcessQueue: true,
        addRemoveLinks: true,
        hiddenInputContainer: `#${uv__dropzoneId}`, // element the hidden input field will be appended to
        dictDefaultMessage: 'Upload a video',
        dictFileTooBig: 'Your selected file is too big to continue, please make sure to reduce the size and upload it again',
        dictInvalidFileType: 'Your uploaded file has wrong file type, please upload one of: pdf, jpg, png, doc, docx',
        dictCancelUpload: '',
        dictRemoveFile: '',
        previewTemplate: uv__previewTemplate,
    });

    uv__dropzone.on('addedfile', file => {
        const position = file.name.lastIndexOf('.');
        const extension = file.name.substring(position + 1);

        file.previewElement.querySelector('.e-dropzone__extension').innerText = extension;
    });

    uv__dropzone.on('success', (file, response) => {
        uv__dropzoneContainer.querySelectorAll('.dz-message')
            .forEach(message => message.parentNode.removeChild(message));

        uv__dropzoneContainer.querySelectorAll('.dz-preview')
            .forEach(preview => preview.parentNode.removeChild(preview));

        if (response) {
            uv__dropzoneContainer.querySelector('.e-dropzone__success-url').value = response;
        }

        uv__dropzoneContainer.querySelector('.e-dropzone__success').classList.add('e-dropzone__success--show');

        uv__dropzone.disable();
    });

    uv__dropzone.on('error', (file, errorMsg, xhrError) => {
        if (uv__dropzoneElement !== null) {
            uv__dropzoneElement.classList.add('error');
        }

        if (xhrError !== undefined) {
            const error = uv__dropzoneContainer.querySelector('.e-dropzone__error--api');

            if (error !== null) {
                error.classList.add('show');
            }
        } else {
            const error = uv__dropzoneContainer.querySelector('.e-dropzone__error--custom');

            if (error !== null) {
                error.querySelector('span').innerText = errorMsg;
                error.classList.add('show');
            }
        }

    });

    uv__dropzone.on('removedfile', (file) => {
        if (uv__dropzoneElement !== null) {
            uv__dropzoneElement.classList.remove('error');

            uv__dropzoneContainer.querySelectorAll('.e-dropzone__error').forEach(errorObj => {
                errorObj.classList.remove('show');
            })
        }
    });


    /**
     * Form: Send
     */
    const applicationForm = document.getElementById('application-form');
    const applicationForm__sendBtn = document.getElementById('application-form__send');

    applicationForm__sendBtn.addEventListener('click', s__onSend);

    // We need to copy entires from applicationForm to Dropzone form
    uf__dropzone.on('sending', (file, xhr, formData) => {
        // If Dropzone FormData doesnt have 'application_type', which is first
        // applicationForm entry, it means entries hasn't been copied yet
        if (!formData.has('applicationType')) {
            const applicationFormData = new FormData(applicationForm);

            for (let entry of applicationFormData.entries()) {
                formData.set(entry[0], entry[1]);
            }
        }
    });

    function s__onSend(event) {
        event.preventDefault();

        if (!applicationForm.checkValidity()) {
            return false;
        }

        uf__dropzone.processQueue();
    }
})
