$(() => {

    // let gallery_swiper = document.getElementById('gallerySwiper');
    const gallerySwiper = $('.e-gallery-swiper');

    if (gallerySwiper) {
        let swiper = new Swiper(gallerySwiper, {
            speed: 600,
            direction: "horizontal",
            resistanceRatio: 0,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false
            },
            navigation: {
                prevEl: ".e-gallery-swiper__navigation-button--prev",
                nextEl: ".e-gallery-swiper__navigation-button--next",
                disabledClass: 'disabled'
            },
            pagination: {
                el: ".e-gallery-swiper__fraction",
                type: "custom",
                renderCustom: function (swiper, current, total) {
                    return ("0" + current).slice(-2);
                }
            },
            breakpoints: {
                992: {
                    allowTouchMove: false
                }
            }
        });
    }

    const products_swiper = $('.e-products-swiper');

    if (products_swiper.length) {
        let programsSwiper = new Swiper(products_swiper, {
            paginationClickable: true,
            centeredSlides: true,
            slidesPerView: 1,
            spaceBetween: 16,
            // autoHeight: true,
            navigation: {
                prevEl: ".e-products-swiper__navigation-button--prev",
                nextEl: ".e-products-swiper__navigation-button--next",
                disabledClass: "disabled"
            },
            pagination: {
                el: '.e-products-swiper__pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                320: {
                    // slidesPerView: 1
                },
                768: {
                    // slidesPerView: 1,
                },
                992: {
                    // slidesPerView: 1,
                    allowTouchMove: false,
                }
            },
            on: {
                init: function () {
                    setTimeout(function () {
                        const swiperBox = products_swiper.closest('.m-product-slider__box');
                        swiperBox.css('minHeight', swiperBox.outerHeight());
                    }, 400);
                }
            }
        });

        // programsSwiper.init();
        // programsSwiper.snapGrid = programsSwiper.slidesGrid.slice(0);
    }

    const swiper_simple = $('.e-simple-slider');

    if (swiper_simple.length) {
        let simpleSlider = new Swiper(swiper_simple, {
            // init: false,
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 30,
            navigation: {
                prevEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--prev",
                nextEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto'
                },
                768: {
                    slidesPerView: 'auto',
                },
                992: {
                    slidesPerView: 'auto',
                    allowTouchMove: false,
                }
            }
        });
    }

    const featured_slider = $('.m-featured-slider');

    if (featured_slider.length) {
        let featuredSlider = new Swiper(featured_slider, {
            // init: false,
            speed: 600,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 30,
            navigation: {
                prevEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--prev",
                nextEl: ".e-simple-slider__navigation .e-simple-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto'
                },
                768: {
                    slidesPerView: 'auto',
                },
                992: {
                    slidesPerView: '',
                    allowTouchMove: false,
                },
                1440: {
                    slidesPerView: 4,
                    allowTouchMove: false,
                }
            }
        });
    }

    const tabs_swiper = $('.e-tabs-swiper');

    if (tabs_swiper.length) {
        let tabsSwiper = new Swiper(tabs_swiper, {
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            spaceBetween: 20,
            breakpoints: {
                320: {
                    slidesPerView: 'auto'
                },
                992: {
                    slidesPerView: 3,
                    allowTouchMove: false
                }
            }
        });
    }

    const images_carousel = $('.e-images-carousel');

    if (images_carousel.length) {
        let tabsSwiper = new Swiper(images_carousel, {
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 40,
            loop: true,
            navigation: {
                prevEl: ".e-images-carousel__navigation-button--prev",
                nextEl: ".e-images-carousel__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
            }
        });
    }

    const reviews_swiper = $('.m-reviews__swiper');

    if (reviews_swiper.length) {
        let reviewsSwiper = new Swiper(reviews_swiper, {
            speed: 600,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            paginationClickable: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: false,
            navigation: {
                prevEl: ".m-reviews__navigation-button--prev",
                nextEl: ".m-reviews__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
            },
            pagination: {
                el: '.m-reviews__pagination',
                type: 'bullets',
                clickable: true
            },
        });
    }

});


const timeline = $('.e-timeline-carousel');

if (timeline.length) {
    let featuredSlider = new Swiper(timeline, {
        watchSlidesProgress: false,
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 1,
        navigation: {
            prevEl: ".e-timeline-carousel__navigation-button--prev",
            nextEl: ".e-timeline-carousel__navigation-button--next",
        },
        effect: 'fade',
        fadeEffect: { crossFade: true },
    });

}

const group = $('.e-group-carousel');

if (group.length) {
    let featuredSlider = new Swiper(group, {
        effect: 'coverflow',
        grabCursor: false,
        slidesPerView: 'auto',
        spaceBetween: 30,
        coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },

        loop: true,
        navigation: {
            prevEl: ".m-group__prev",
            nextEl: ".m-group__next",
        },
        on: {
            slideChange: function() {
                $('.m-group__prev').show();
            }
        }
    });

}

