$(() => {

    if ($('.m-hero-slider__gallery').length) {
        const heroSwiper = $('.m-hero-slider__gallery');
        const heroBgSwiperId = '#' + $('.m-hero-slider__bg-gallery').attr('id');

        const heroBgSwiperInstance = new Swiper(heroBgSwiperId, {
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: false,
            paginationClickable: false,
            slidesPerView: 1,
            effect: 'fade',
        });

        const heroSwiperInstance = new Swiper(heroSwiper, {
            speed: 1000,
            direction: "horizontal",
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            paginationClickable: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            effect: 'fade',
            autoplay: {
                delay: 5000,
            },
            navigation: {
                prevEl: ".m-hero-slider__navigation-button--prev",
                nextEl: ".m-hero-slider__navigation-button--next",
                disabledClass: "disabled"
            },
            breakpoints: {
            },
            pagination: {
                el: '.m-hero-slider__pagination',
                type: 'bullets',
                clickable: true
            },
            on: {
                slideNextTransitionStart: function() {
                    const classNumber = $(this.el).find('.swiper-slide-active').data('slide-to-wheel');
                    spinWheel(classNumber);
                    heroBgSwiperInstance.slideTo(classNumber - 1);
                },
                slidePrevTransitionStart: function() {
                    const classNumber = $(this.el).find('.swiper-slide-active').data('slide-to-wheel');
                    spinWheel(classNumber);
                    heroBgSwiperInstance.slideTo(classNumber - 1);
                },
            }
        });
    }

    function spinWheel(slideNumber) {
        const wheel = $('.m-hero-slider .e-animation-wheel__window ul');
        wheel.attr('class', '');
        wheel.addClass(`rotate-${slideNumber}`);
    }
});
