$(() => {
    const activeCountries = [];

    if ($('#myearth').length) {

        const willRotate = $('#myearth').data('rotate');

        $('.m-company-overview').each(function() {
            const countryCode = $(this).data('country-code');
            activeCountries.push(countryCode);
        });

        const activeMapStyles = '#' + activeCountries.join(', #') + ' { fill: #e2001a; }';

        var myearth = new Earth( "myearth", {
            location: { lat: 21.481387307294426, lng: 17.871879031717256 },
            mapLandColor: '#004777',
            mapSeaColor: 'rgba(0, 56, 95, 0.80)',
            mapBorderWidth: 0.2,
            mapBorderColor: '#fff',
            mapStyles: activeMapStyles,
            mapHitTest: true,
            transparent: true,
            light: 'simple',
            lightAmbience: 0.7,
            autoRotate: willRotate,
            autoRotateSpeed: 0.4,
            quality: 4
            /* more earth options here */
        } );

        myearth.addEventListener( 'click', function( event ) {

            if ( ! event.id || event.id == 'SEA' ) return; // no country hit
            const target = $(`[data-country-code=${event.id}`);

            if ($('.m-company-overview.open').not(target).length) {
                $('.m-company-overview').not(target).removeClass('open');
                $('.m-company-overview').hide();
            }
            target.show(100);
            setTimeout(() => {
                target.addClass('open');
                setTimeout(() => {
                    initLandingSwipers();
                }, 500);
            }, 100);

        } );

        myearth.addEventListener( "ready", function() {

            $('.e-earth-globe').addClass('globe-loaded');
            document.getElementById('landing-spinner').style.display = 'none';

        } );

        $('.m-company-overview__close').on('click', function() {
            const modal = $(this).closest('.m-company-overview');
            modal.removeClass('open');
            setTimeout(function() {
                modal.hide();
            }, 300);
        });
    }

});

function initLandingSwipers() {

    const landingSwiper = $('.m-company-overview__swiper');
    const landingSwiperArray = [];

    landingSwiper.each(function(index) {
        const landingSwiperUniqueId = '#' + $(this).attr('id');
        const parent = $(landingSwiperUniqueId).closest('.m-company-overview');
        const prevBtn = parent.find('.m-company-overview__navigation-button--prev');
        const nextBtn = parent.find('.m-company-overview__navigation-button--next');
        const pagin = parent.find('.m-company-overview__pagination');
        const landingSwiperUnique = $(landingSwiperUniqueId);

        const landingSwiperInstance = new Swiper(landingSwiperUnique, {
            spaceBetween: 0,
            simulateTouch: false,
            allowTouchMove: false,
            paginationClickable: true,
            slidesPerView: 1,
            autoHeight: true,
            effect: 'fade',
            crossFade: true,
            speed: 600,
            pagination: {
                el: pagin,
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: prevBtn,
                nextEl: nextBtn,
            },
            on: {
                slideChange: function() {
                    pagin.find('.swiper-pagination-bullet').removeClass('swiper-pagination-bullet-active');
                    pagin.find('.swiper-pagination-bullet').eq($(this)[0].snapIndex).addClass('swiper-pagination-bullet-active');
                    $(this)[0].pagination.update();
                }
            }
        });

        landingSwiperArray.push(landingSwiperInstance);
    });
}
